import React from 'react'
import { Title } from '../../components/UI/Title'
import { Subtitle } from '../../components/UI/Subtitle'
import { Link } from 'gatsby'

export const UnderstandingCERsContent = () => {
  return (
    <div>

      <article tw="mt-10 space-y-6">
        <Title>
          Understanding Controllable Electronic Records (CERs) and the 
          Proposed Uniform Commercial Code Revisions
        </Title>
        <p>
          While they have been traditionally called "tokens", there's a new 
          legal term that's a better definition - a "Controllable Electronic 
          Record".
        </p>
        <p>
          As digital assets continue to gain prominence in the global 
          economy, the need for a clear legal framework to govern these assets 
          has become increasingly important. To address this, the Uniform 
          Commercial Code (UCC) is proposing amendments that introduce the 
          concept of Controllable Electronic Records (CERs). This blog post 
          will provide a comprehensive explanation of CERs, how they are 
          defined, and how the legal language is specified in the proposed 
          revisions to the UCC.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          What is a Controllable Electronic Record (CER)?
        </Subtitle>
        <p>
          A Controllable Electronic Record (CER) is a record stored in an 
          electronic medium that can be subjected to "control" [2]. CERs 
          encompass various digital assets, including virtual currency, 
          tokenized shares of stock or other investment, non-fungible 
          tokens (NFTs), and digital assets with embedded payment rights 
          [6]. The proposed new Article 12 of the UCC aims to provide rules 
          for transactions involving digital assets and specifically refers 
          to these intangible assets as CERs [5].
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Defining "Control" in the Context of CERs</Subtitle>
        <p>
          In order for a person to have "control" of a CER, they must meet the 
          following criteria [1]: 
        </p>
        <ol tw="list-decimal ml-10">
          <li>
            The power to enjoy "substantially all the benefit" of the CER.
          </li>
          <li>
            The exclusive power to prevent others from enjoying "substantially 
            all the benefit" of the CER.
          </li>
        </ol>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Exclusions</Subtitle>
        <p>
          It's important to note that not all digital assets are considered 
          CERs. By definition, a CER excludes digital assets that are not 
          subject to "control" as well as those that are already subject to 
          other commercial laws, such as the Electronic Signatures in Global 
          and National Commerce Act (E-SIGN), the Uniform Electronic 
          Transactions Act, or other articles of the UCC [4].
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Legal Language in the Revised UCC</Subtitle>
        <p>
          In the proposed amendments to the UCC, Section 12-102(1) defines a 
          CER as a "record stored in an electronic medium that can be 
          subjected to control" [8]. This definition specifically excludes 
          many types of digital assets already subject to other commercial 
          laws.
        </p>
        <p>
          The new Article 12 aims to provide clarity regarding the transfer 
          of property rights in CERs [9]. To have "control" of a CER, a 
          person must possess "the power to enjoy 'substantially all the 
          benefit' of the CER," and this power does not have to be exclusive 
          [10].
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Examples of CERs</Subtitle>
        <p>
          Some examples of digital assets that could be considered CERs under 
          the proposed amendments include:
        </p>
        <ol tw="list-decimal ml-10">
          <li>
            Virtual currencies (e.g., Bitcoin, Ethereum): These 
            cryptocurrencies can be stored electronically and are susceptible 
            to control by the person holding the private key associated with 
            the currency.
          </li>
          <li>
            Non-fungible tokens (NFTs): NFTs are unique digital assets that 
            can represent various forms of digital art, collectibles, or 
            virtual goods. Like virtual currencies, NFTs can be stored 
            electronically and controlled by the person holding the private 
            key associated with the token.
          </li>
          <li>
            Digital assets with embedded payment rights: These digital assets 
            may include smart contracts or other electronically stored 
            agreements that provide the holder with the right to receive 
            payments.
          </li>
          <li>
            Tokenized Securities: These are digital assets or smart contracts 
            which represent an underlying share of stock, a limited partnership 
            unit, or other actual form of investment security ownership.
          </li>
        </ol>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Conclusion</Subtitle>
        <p>
          The proposed amendments to the Uniform Commercial Code aim to address 
          the growing prominence of digital assets in the global economy by 
          introducing the concept of Controllable Electronic Records (CERs). 
          These amendments provide a legal framework for the control and transfer 
          of digital assets, such as virtual currencies, non-fungible tokens, 
          and digital assets with embedded payment rights.
        </p>
        <p>
          By understanding the definition and legal language surrounding CERs, 
          individuals and businesses can better navigate the evolving landscape 
          of digital asset transactions and ensure compliance with the Uniform 
          Commercial Code.
        </p>
        <br />
        <br />
        <p>
          Michael Hiles, Founder & CEO of 10XTS
        </p>
      </article>
      <br />
      <br />
      <br />
      <br />
      <hr />

      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [1] “A “controllable electronic record” is a record in electronic form that is susceptible to “control.” For a person to have “control” of a CER, the person must have: the power to enjoy “substantially all the benefit” of the CER, the exclusive power to prevent others from enjoying “substantially all the benefit” of the CER, and”<br />
          URL: <Link to="https://www.americanbar.org/groups/business_law/publications/blt/2022/04/2022-amendments-ucc/" tw="hover:text-blue-800">https://www.americanbar.org/groups/business_law/publications/blt/2022/04/2022-amendments-ucc/</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [2] “A “controllable electronic record” (CER) is a record stored in an electronic medium which can be subjected to “control” under 12-105 but does not include a controllable account, payment intangible, deposit account, electronic copy of a record evidencing chattel paper, electronic documents of title, electronic money, investment property or a …”<br />
          URL: <Link to="https://www.hmblaw.com/blog/the-uccs-proposed-article-12-controllable-electronic-records/" tw="hover:text-blue-800">https://www.hmblaw.com/blog/the-uccs-proposed-article-12-controllable-electronic-records/</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [3] “A “controllable electronic record” is a record in electronic form that is susceptible to “control.” For a person to have “control” of a CER, the person must have: the power to enjoy “substantially all the benefit” of the CER, the exclusive power to prevent others from enjoying “substantially all the benefit” of the CER, and”<br />
          URL: <Link to="https://businesslawtoday.org/2022/03/proposed-2022-amendments-uniform-commercial-code-digital-assets/" tw="hover:text-blue-800">https://businesslawtoday.org/2022/03/proposed-2022-amendments-uniform-commercial-code-digital-assets/</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [4] “By definition, a CER excludes any digital assets that are not subject to “control” as well as those that are already subject to other commercial laws such as E-SIGN, the Uniform Electronic Transactions Act or other articles of the UCC.”<br />
          URL: <Link to="https://www.skadden.com/insights/publications/2022/10/amendments-to-uniform-commercial-code" tw="hover:text-blue-800">https://www.skadden.com/insights/publications/2022/10/amendments-to-uniform-commercial-code</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [5] “Summary of Key Uniform Commercial Code (UCC) Amendments. Article 12: A proposed new Article 12 of the UCC provides rules for transactions involving certain new types of digital assets, including cryptocurrency and non-fungible tokens.Under the proposed new Article 12, these intangible assets are called “controllable electronic records,” or “CERs.””<br />
          URL: <Link to="https://www.gtlaw.com/en/insights/2022/12/proposed-ucc-amendments-to-article-12-shed-new-light" tw="hover:text-blue-800">https://www.gtlaw.com/en/insights/2022/12/proposed-ucc-amendments-to-article-12-shed-new-light</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [6] “Article 12 introduces the concept of the controllable electronic records (CER). A CER is defined as a record of information in electronic form that is susceptible to control. Examples include virtual currency, non-fungible tokens, and digital assets with embedded payment rights.”<br />
          URL: <Link to="https://www.wolterskluwer.com/en/expert-insights/uniform-commercial-code-ucc-amended-to-address-emerging-technologies" tw="hover:text-blue-800">https://www.wolterskluwer.com/en/expert-insights/uniform-commercial-code-ucc-amended-to-address-emerging-technologies</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [7] “Controllable electronic record (CER): A record of information stored electronically that is susceptible to control and that a person has the power to use. Controllable account: An account that is evidenced by a CER, and the agreement of the account debtor (obligor) obligated on the account to pay the person who controls the CER.”<br />
          URL: <Link to="https://www.orrick.com/en/Insights/2022/10/The-Uniform-Commercial-Code-and-Digital-Assets-What-You-Need-to-Know" tw="hover:text-blue-800">https://www.orrick.com/en/Insights/2022/10/The-Uniform-Commercial-Code-and-Digital-Assets-What-You-Need-to-Know</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [8] “The new Section 12-102(1) defines a CER as a “record stored in an electronic medium that can be subjected to control” and specifically excludes many types of digital assets already …”<br />
          URL: <Link to="https://www.natlawreview.com/article/2022-uniform-commercial-code-amendments-address-emerging-digital-technologies" tw="hover:text-blue-800">https://www.natlawreview.com/article/2022-uniform-commercial-code-amendments-address-emerging-digital-technologies</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [9] “Amendments to Uniform Commercial Code Aim To Provide Clarity … The new article governs the transfer of property rights in a “controllable electronic record” (CER), which is defined as a record stored in an electronic medium that can be subjected to “control” (discussed below). By definition, a CER excludes any digital assets”<br />
          URL: <Link to="https://www.skadden.com/-/media/files/publications/2022/10/amendments_to_uniform_commercial_code_aim_to_provide_clarity_on_the_transfer_of_digital_assets.pdf" tw="hover:text-blue-800">https://www.skadden.com/-/media/files/publications/2022/10/amendments_to_uniform_commercial_code_aim_to_provide_clarity_on_the_transfer_of_digital_assets.pdf</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [10] “Article 12 covers only digital assets that are CERs. A CER is a record in electronic form that is susceptible to “control.” (e.g., bitcoin). D. Control. For a person to have “control” of a CER, the person must have. · “the power to enjoy “substantially all the benefit” of the CER (does not have to be exclusive);”.”<br />
          URL: <Link to="https://web.nebankers.org/handbook/results.aspx?ContentID=2169" tw="hover:text-blue-800">https://web.nebankers.org/handbook/results.aspx?ContentID=2169</Link>
        </p>
      </cite>

    </div>
  )
}
