import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../../components/Layout/Footer'
import { Container } from '../../components/UI/Container'
import { UnderstandingCERsContent } from '../../views/articles/UnderstandingCERs'
import { Navbar } from '../../components/Layout/Navbar'

const UnderstandingCERsPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Understanding Controllable Electronic Records (CERs) and the Proposed Uniform Commercial Code Revisions — Commercium Bank, Inc</title>
      </Helmet>

      <Navbar />

      <Container tw="pt-32 pb-20">
        <UnderstandingCERsContent />
      </Container>

      <Footer />
    </div>
  )
}

export default UnderstandingCERsPage
